import React, { useState, useEffect } from "react"
// import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"

import Popup from "react-popup"

import Header from "../components/header"
import ResponsiveAppBar from "../components/ResponsiveAppBar"
import NavV2 from "../components/NavV2"
import NextButton from "../components/NextButton"
// 
import TestiCards from "../components/testicards"
import FAQ from "../components/FAQ"
import FooterContainer from "../components/FooterContainer"
import Signup from "../components/Signup"
import ExplanationVideo from "../components/ExplanationVideo"
import Stepbystep from "../components/Stepbystep"

import gray_logo_text from "../images/cloudCastleLogo_dark_gray.png"
import main_landing_page_qr from "../images/main_landing_page_qr.png"
import EbookCloudCastlesCover from "../images/EbookCloudCastlesCover.png" 

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import phones from "../assets/Phones2.svg"
import shadowPhones from "../assets/shadowPhones.svg"

import vectorBackground from "../assets/VectorBackground.svg"
import backgroundSlide from "../assets/BackgroundSlide.svg"
import wave from "../assets/Wave.svg"
import waveTop from "../assets/WaveTop.svg"

import Fab from "@mui/material/Fab"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css"

import Helmet from "react-helmet"
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import black_logo_text from "../images/black_logo_text.png"

import { hitCCBE, sendCloudCastles,getBothTokens, currentcyFormat } from '../components/Helpers'

const StartManagingRealEstate = () => {
  const [email, setEmail] = React.useState("")
  const [phoneNumber, setPhoneNumber] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [emailError, setEmailError] = React.useState("")
  const [emailSent, setEmailSent] = React.useState("")
  const [value,setValue] = React.useState("") 

  const onChange = (e) => {
      const re = /^[0-9\b]+$/;

      // if value is not blank, then test the regex

      if (e.target.value === '' || re.test(e.target.value)) {
         
         
         if (phoneNumber.length < 10){
            setPhoneNumber(e.target.value)
            console.log("phoen",phoneNumber)
         }
      }
  }

  const handleSumit = (e) => {


    console.log(rawData)
    e.preventDefault()
    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


    if (re.test(email)) {
      var rawData = JSON.stringify({
        "email": email,
        "phone": phoneNumber,
        "first_name": first_name,
        "last_name": last_name,
        });

      hitCCBE(`${process.env.CASTLE_API_URL}/api/weblead/`,rawData,'POST')
      setEmail(e.target.value)
      setEmailError("")
    } else {
      // invalid email, maybe show an error to the user.
      console.log(email)
      setEmailSent("")
      setEmailError("Please set a valid email")

    }
  }

  const updatePhone = (e) =>{
    console.log(e.target.value)
    const n = e.target.value
    // setPhoneNumber()
    console.log(n.count)

  }

  function valuetext(value) {
    setValue(value)
    console.log(value)
    return `${value}°C`;
  }

  function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Cloud Castles Vacation Homes</title>
        <meta
          name="google-site-verification"
          content="lRDxE-lZUVoBRi5yV2AHoVkcaALQME9xGG-XwQYIbcU"
        />{" "}
        <meta
          name="keywords"
          content="Real estate investment manager, how to own real estate, is real estate investing a business, how to start real estate investing"
        />
        <meta
          name="description"
          content="Allowing Anyone To Own Anywhere. Your start as a home Real estate investing manager."
        />
      <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-207899923-1"
        ></script>
        <script type="application/ld+json">{`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('config', 'UA-207899923-1');
  `}</script>
      </Helmet>
      <ResponsiveAppBar />
      <Body>
        <GlobalFonts />
        {/*<Fab style={style} onClick={routeChange}>
          <Logo2 src={gray_logo_text}></Logo2>
          <Logo3 src={main_landing_page_qr}></Logo3>
          <PText2>Download Now</PText2>
        </Fab>*/}
        <Tbackground>
{/*          <TopBackground src={topbackground} />
          <TopBackground1 src={topbackground} />
          <TopBackground2 src={topbackground2} />*/}
        </Tbackground>
        <Hero>
          <Hero1>
          <LogoImg src={black_logo_text}/>
          <H1Text style={{color:"black"}}>Build Your Portfolio</H1Text>
          <H1Text style={{color:"black"}}>{currentcyFormat.format(value)}</H1Text>
          <PText2 style={{color:"black"}}>Download Now</PText2>
             <Box sx={{ width: 400 }}>
              <Slider
                size="large"
                aria-label="Group Invest"
                defaultValue={100000}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={10000}                
                min={10000}
                max={1000000}
              />
              
            </Box>
          </Hero1>

          <Hero2>

            <H1Text style={{color:"black"}}>Start Your Real Estate Fund Today</H1Text>
            <H1Text style={{color:"black"}}>Join the waitlist</H1Text>
            <PText style={{color:"black"}}>
              Create your Cloud Castles profile and let other investors join your deals on your terms.
            </PText>
        <CTAForm
          style={{
            flexDirection: "row",
            display: "grid",
            // width:"100%"
            gridTemplateColumns:"repeat(auto-fill,minmax(400px,1fr));"
            // jusityitems: "center",
            // alignitems: "center",
          }}
          // onSubmit={toForm}
        >
        <CTAInput
          type="text"
          className="form-control"
          name="first_name"
          placeholder="First Name"
          onChange={(event) => setFirstName(event.target.value)}          
        />

        <CTAInput
          type="text"
          className="form-control"
          name="last_name"
          placeholder="Last Name"
          onChange={(e) => setLastName(e.target.value)}
        />
        </CTAForm>
        <CTAInput
          type="tel"        
          className="form-control"
          name="phoneNumber"
          placeholder="555-555-5555"
          value={phoneNumber}
          onChange={onChange}
        />
        <CTAInput
          type="text"
          className="form-control"
          name="email"
          placeholder="Email"
          onChange={event => setEmail(event.target.value)}
          error={emailError}
        />
        <span style={{ color: "red" }}>{emailError}</span>
        <span style={{ color: "black" }}>{emailSent}</span>
        <CTAButton onClick={handleSumit}type="submit">Learn More</CTAButton>
          </Hero2>
        </Hero>
          <FooterContainer />
        
      </Body>          
    </>
  )
}

export default StartManagingRealEstate

const style = {
  margin: 0,
  top: "auto",
  left: 20,
  bottom: 20,
  height: 150,
  width: 150,
  zIndex: 100,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  left: "auto",
  position: "fixed",
}

const Body = styled.div`
  position: relative;
`

const Tbackground = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`

const Hero = styled.div`
  max-width: 100%;
  overflow-x: hidden;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 100px auto;

  @media only screen and (max-width: 625px) and (min-width: 100px) {
    flex-direction: column-reverse;
    margin: -70px 50px 50px -20px;
  }
`

const Logo = styled.img`
  max-width: 40%;

  /*padding: 0px 0px 0px 30px;*/

  margin-right: 30px;
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const Logo2 = styled.img`
  /*max-width: 40%;*/

  /*padding: 0px 0px 0px 30px;*/
  height: 30px;
  /*margin: 4px auto;*/
  /*margin-right: 30px;*/
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const Logo3 = styled.img`
  /*max-width: 40%;*/
  height: 50px;
  /*padding: 0px 0px 0px 30px;*/

  margin: 4px auto;
  position: relative;
  @media only screen and (max-width: 40em) {
  }
`

const Hero1 = styled.div`
  width:50%;
  display: flex;
  flex-direction: column;
 align-items: center;
  justify-content: center;
  // margin: 100px auto 0px 0px;
// padding 100px auto auto 100px;
  // align-items:space-evenly;

  // margin: auto;

  @media only screen and (max-width: 625px) and (min-width: 100px) {

    // margin: 100px auto 50px 100px;

  }  

  
`

const Hero2 = styled.div`
  
  
  margin:  auto;
  padding 100px 0px 0px 100px;
    flex: 1;
  align-items:space-evenly;
  justify-content: center;
`

const CTADiv = styled.div``

const LogoImg = styled.img`
  width:60%;
`

const TopBackground = styled.img`
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -4.95%;
  bottom: 78.14%;
  z-index: -10;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 800px) and (min-width: 426px) {
    left: -917.75px;
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    width: 1712.46px;
    height: 1556.42px;
    left: -717.75px;
    top: -656.2px;

    background: #8bbef3;
    border-radius: 333px;
    transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  }
`

const TopBackground1 = styled.img`
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -40.95%;
  bottom: 78.14%;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground0 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -14.63%;
  right: 0.75%;
  top: -0.95%;
  bottom: 8.14%;

  opacity: 0.1;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.9, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground2 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -1.72%;
  right: 32.8%;
  top: -16.64%;
  bottom: 81.18%;

  background: #8bbef3;
  opacity: 0.05;
  border-radius: 333px;
  transform: matrix(1, -0.1, 0.08, 1, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
const Phones = styled.img`
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  margin-left: auto;
  margin-right: 40px;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 80%;

  @media only screen and (max-width: 1280px) and (min-width: 900px) {
    /*margin: 10px 10px 100px 100px;*/
  // margin-left: 280px;
  // margin-right: 0px;
  // margin-top: auto;
  // margin-bottom: auto;

  }
`
const Shadowphone = styled.img`
  position: absolute;
  width: 829.14px;
  height: 366.75px;
  right: 100.75px;
  top: 400.63px;

  margin: 150px auto 0px auto;
  @media only screen and (max-width: 625px) and (min-width: 100px) {
    // margin: 200px -300px 0px 100px;
  }
`

const Testi = styled.h2`
  position: relative;
  width: 551px;
  height: 55px;
  /*left: 320.32px;*/
  top: 0.21px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36.9714px;
  line-height: 55px;
  text-transform: uppercase;

  color: #000000;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    left: 70.32px;
  }
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 35.8802px;
  line-height: 119%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  margin: 30px auto;
  padding: auto;

  color: #ffffff;
  @media only screen and (max-width: 600px) and (min-width: 100px) {
  }
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const PText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.7436px;
  line-height: 151%;
  /* or 25px */
  margin: 30px auto;
  color: #fafdff;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const PText2 = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10.7436px;
  line-height: 151%;
  /* or 25px */
  /*margin: 30px auto;*/
  color: #1d293f;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  padding-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16.1461px;
  line-height: 24px;

  color: #ffffff;

  background: #c73967;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAForm = styled.form`
  margin: auto;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }
`

const CTAInput = styled.input`
  display: flex;
  margin:2px;
  // padding: 16.1461px 30.7772px;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1461px;
  line-height: 24px;

  color: black;

  border: 0.897006px solid #8578fc;
  box-sizing: border-box;
  filter: drop-shadow(0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26));
  border-radius: 40.3653px;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

/*
const Wave = styled.img`
  position: absolute;
  width: 100%;
  z-index: 0;
    top: 3139.21px;
`

const WaveTop = styled.img`
  position: absolute;
  width: 100%;
  opacity:80%;
  z-index: 0;
    top: 3139.21px;
`
*/

const FooterDiv = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  left: 0px;
  top: 0.21px;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const SignupDiv = styled.div`
  position: relative;

  width: 100%;
  height: 300px;
  z-index: 2;
  top: 55.21px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

